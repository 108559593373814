import Digni from '../Assets/Digni Logo.png'
import UnicornFarmLogo from '../Assets/UFLogo.png';
import ThryveLogo from '../Assets/thryvelogo.png' 
import Card from '../components/Card'
import TitleSection from '../components/TitleSections';

export default function Projects() {
    return (
        <header id="projects">
            <TitleSection
            title='Projects'
            />
            <div class='mt-16 flex-nowrap break-after-page grid md:grid-cols-3 h-full lg:h-screen mb-4'>
                <Card 
                cardImage={Digni}
                cardTitle='Digni'
                cardInfo='Working on helping people find meaning, value, and dignity in their work by building a union for the 21st Century'
                buttonText='Learn More'
                sendButtonTo='https://www.digni.work'
                buttonStyle="font-medium text-[16px] px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-violet-700 hover:bg-gradient-to-r from-stone-500 hover:to-stone-500  relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass"

                />
                <Card 
                cardImage={ThryveLogo}
                cardTitle='Thryve'
                cardInfo='Built a communication platform for churches with my brother all centered around SMS. Got acquired.'
                buttonText='Learn More'
                sendButtonTo='https://www.thryve.io'
                buttonStyle="font-medium text-[16px] px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-gradient-to-r from-emerald-300 to-blue-400 hover:from-zinc-400 hover:to-zinc-400  relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass"

                />
                <Card 
                cardImage={UnicornFarmLogo}
                cardTitle='Unicorn Farm'
                cardInfo='A community of people building magical things! So far, there are three of us so if you want to build something magical - come join!'
                buttonText='Learn More'
                sendButtonTo='https://www.digni.work'
                className="mb-6"
                buttonStyle="font-medium text-[16px] px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-gradient-to-r from-purple-800 to-rose-300 hover:from-slate-600 hover:to-slate-600  relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass"

                />
            </div>
        </header>
       
    




    )
}