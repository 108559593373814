import './App.css';
import React from 'react';
import StickyBox from 'react-sticky-box';
import About from './pages/About';
import Header from './components/Header';
import Hero from './pages/Hero';
import Projects from './pages/Projects';

import ContactForm from './pages/ContactForm';
import Sidebar from './components/Sidebar';

function App() {
  return (
    <div style={{display: "flex", alignItems: "flex-start"}} className="row font-light">
    <StickyBox offsetTop={20} offsetBottom={20} className="mr-auto hidden lg:flex ml-auto">
      <div><Sidebar /></div>
    </StickyBox>
    <div>
        <Header />
        <Hero />
        <About />
        <Projects />
        <ContactForm />
    </div>
  </div>
  );
}



export default App;
