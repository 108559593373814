import Button from "./Button"


export default function Card(props) {
    return (
        <div class="container max-w-sm mx-auto gap-3">
            <div class="bg-white shadow-md border border-gray-200 rounded-lg max-w-md mb-5 drop-shadow-sm">
                <img class="rounded-t-xl mx-auto w-52 h-52 p-2" src={props.cardImage} alt=""/>
                <div class="p-5">
                    <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2">{props.cardTitle}</h5>
                    <p class="font-normal text-gray-700 mb-3">{props.cardInfo}</p>
                    <Button 
                    text={props.buttonText}
                    sendTo={props.sendButtonTo}
                    isButtonCentered={false}
                    buttonStyle={props.buttonStyle}
                    />
                </div>                      
            </div>
        </div>    
    )
}