import AboutPic from '../Assets/aboutpic.jpg'
import TitleSection from '../components/TitleSections'

export default function About() {
    return (

      <header id="about" className='mt-5'>
        <TitleSection 
        title='About'
        />
        
        <div class="py-16 bg-white h-full lg:h-screen">  
  <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
      <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
        <div class="md:5/12 lg:w-5/12">
          <img className='flex-initial w-102 h-102 max-w-sm relative sm:mt-0 mt-10 px-6 sm:px-0 mx-auto p-2' src={AboutPic} alt="pictureabout" loading="lazy" width="" height=""/>
        </div>
        <div class="md:7/12 lg:w-6/12">
          <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">Greetings from Orlando, FL</h2>
          <p class="mt-6 text-gray-600">Hello! I am somewhere around 25 years old (depending on when you're reading this) and I live in the sunshine state! I really like to learn, build, and create things (hence this website!) I am married to someone who is equally as interested and puts out some awesome work! Go check her out at maygauthier.com! We have a wonderful Great Pyrenees named Figgy and we love our life!</p>
          <p class="mt-4 text-gray-600">Thanks for visiting my website and if you want to connect, send me a note below!</p>
        </div>
      </div>
  </div>
</div>
</header>


    )
}