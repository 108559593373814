export default function TitleSection(props) {
    return (
       
       
        <div className="text-center text-5xl font-mono mx-auto">
            <h1>{props.title}</h1>
        </div>
       
      
    )
    }