export default function Button(props) {

    let buttonCentered = "";
    let animateButton = "";
 

    if (props.isButtonCentered === true) {
           buttonCentered = 'flex items-center justify-center';
   } else {
        buttonCentered = 'gap-4 mt-10';
   }

    if (props.isButtonAnimated === true) {
        animateButton = <span class="animate-ping absolute right-0 top-0 w-3 h-3 rounded-full bg-gradient-to-r from-orange -400 to-orange-700 "></span>;
        } else {
     
        }

    return (
        <div class={buttonCentered}>
            <a href={props.sendTo} class={buttonCentered}>
                <button class={props.buttonStyle}>
                    {props.text}
                    {animateButton}
                </button>
            </a>                                       
        </div>
    )
}



