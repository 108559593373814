import React, { useState } from "react";
import Button from "../components/Button";
import TitleSection from "../components/TitleSections";

export default function ContactForm() {
    const [submit, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
            },100);
    };
    if (submit) {
        return (
            <div class="flex min-h-screen items-center justify-start bg-white max-w-full">
                <div class="mx-auto w-full max-w-lg">
                    <h1 class="text-4xl font-medium">Contact me</h1>
                    <p class="mt-3">Thanks for reaching out! We will be in touch soon!</p>
                </div>
            </div>
        )
    } else {
        return (
            <header id="contact">

            <TitleSection 
            title='Contact me!'
            />                
            <div class="mt-8 flex min-h-screen bg-white ">
                <div class="mx-auto w-full max-w-sm">
                    
                    <p class="mt-3">Drop me a note below and I will reach out as soon as possible!</p>
                        <form 
                        action="https://public.herotofu.com/v1/8241f590-32df-11ed-a128-91006467e20b" 
                        method="post"
                        onSubmit={handleSubmit}
                        target
                        >
                            <div class="grid gap-6 sm:grid-cols-2 mt-3">
                            <div class="relative z-0">
                                <input type="text" name="name" class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " />
                                <label class="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Your name</label>
                            </div>
                            <div class="relative z-0">
                                <input type="text" name="email" class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" " />
                                <label class="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Your email</label>
                            </div>
                            <div class="relative z-0 col-span-2">
                                <textarea name="message" rows="5" class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0" placeholder=" "></textarea>
                                <label class="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">Your message</label>
                            </div>
                            </div>
                            <Button
                            text='Send Message'
                            type="submit"
                            isButtonCentered={false}
                            buttonStyle="font-medium text-[16px] px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-gradient-to-r from-stone-900 to-stone-500 hover:from-stone-500 hover:to-stone-900  relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass"

                            />
                        </form>
                </div>
            </div>
            </header>
        )

       
    

    } 
}

