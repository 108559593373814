import Button from "../components/Button";

export default function Hero() {
    return (
        <header id="home">
            <div class="relative mt-20 pt-10 h-full lg:h-screen sm:pt-0 text-center">
                <div class="container mx-auto grid grid-cols-1 md:grid-cols-2 items-center md:gap-4">
                    <div class="content">
                        <p class="text-[40px] lg:text-[45px] xl:text-[55px] font-bold leading-tight mt-5 sm:mt-0 ">
                        Hello!
                        </p>
                        <p class="mt-5 ">
                        It's nice to meet you. 
                        </p>
                        <Button 
                        text='Get in touch'
                        sendTo='#contact'
                        centerButton={true}
                        isButtonAnimated={true}
                        buttonStyle="font-medium text-[16px] px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-gradient-to-r from-stone-900 to-stone-500 hover:from-stone-500 hover:to-stone-900  relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass"
                        />
                    </div>
                        <div class="relative sm:mt-0 mt-10 px-6 sm:px-0">
                            <img class="w-[600px] animate__animated animate__fadeInRight animate__delay-.5s" src="https://images.unsplash.com/photo-1624628639856-100bf817fd35?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8M2QlMjBpbWFnZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60" alt="" />
                        </div>
                </div>
                
            </div>
            
        </header>
    )
}