import React from "react"


export default function Sidebar() {
    return (
        <div className="ml-5">
                <div className="py-10">
                    <a href="#home" className="h-8 text-5xl w-auto sm:h-10 font-mono">Will Gauthier.</a>
                </div>
            <ul className="font-mono text-xl py-3 list-none leading-10">
                <a href="#about"><li>about.</li></a>
                <a href="#projects"><li>projects.</li></a>
                <a href="#connect"><li>connect.</li></a>
                <a href="#contact"><li>contact.</li></a>
            </ul>
        </div>
           
    
    )
}